import { css } from '@emotion/react'
import theme from 'lib/theme'

export default css`
  @tailwind base

  // fonts
  @font-face {
    font-family: 'Helvetica Now';

    font-style: normal;
    src: url('/fonts/5760841/46dd84ea-bb96-46da-973b-d7fcca46437e.woff2') format('woff2'),
      url('/fonts/5760841/0fb90058-c064-40ed-8109-d1fd8633caa2.woff') format('woff');
  }

  @font-face {
    font-family: 'Tiempos';
    font-style: normal;
    src: url('/fonts/3334524/TiemposText-Regular.woff2') format('woff2'),
      url('/fonts/3334524/TiemposText-Regular.woff') format('woff');
  }

  // base
  * {
    box-sizing: border-box;
  }

  html {
    padding: 0;
    margin: 0;
    height: 100%;
  }

  body {
    padding: 0;
    margin: 0;
    height: 100%;
    color: ${theme.colors.primaryText};
    background: ${theme.colors.primary};

    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13px;
    line-height: 1.4em;
    @media only screen and (min-device-width: 375px) {
      font-size: 15px;
      line-height: 1.4em;
    }
  }

  #__next,
  #__safeHydrate {
    height: 100%;
    overflow-y: auto;
  }

  // display images as block elements by default
  img,
  svg {
    display: block;
  }

  // theme-ify links
  a {
    color: ${theme.colors.primaryText};
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      color: ${theme.colors.primaryText};
      text-decoration: underline;
    }
  }

  // remove browser styling from buttons
  button {
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    outline: none;
    padding: 0;
    cursor: pointer;
  }

  h1 {
    font-size: 2rem;
    line-height: 1.3em;
  }

  h2 {
    font-size: 1.4rem;
    line-height: 1.4em;
    font-weight: normal;
    font-family: ${theme.fonts.secondary};
  }

  h3 {
    font-size: 1rem;
    line-height: 1.4em;
    font-weight: normal;
  }

  h1,
  h2,
  h3,
  p {
    margin: 0;
  }

  .indent {
    padding-left: 2rem;
  }

  small,
  .small {
    font-size: 0.8rem;
    line-height: 1.3em;
  }

  iframe {
    margin: 0;
    padding: 0;
    border: none;
  }
`
