import 'emoji-mart/css/emoji-mart.css'
import 'react-medium-image-zoom/dist/styles.css'

import { Global } from '@emotion/react'
import { ThemeProvider } from '@emotion/react'
import { AuthProvider } from 'hooks/useAuth'
import theme from 'lib/theme'
import Head from 'next/head'
import PropTypes from 'prop-types'
import React from 'react'
import { Provider } from 'react-redux'
import { useStore } from 'store'
import globalStyles from 'styles/globalStyles'
import { GlobalStyles as TailwindGlobalStyles } from 'twin.macro'

function SafeHydrate({ children }) {
  return (
    <div id="__safeHydrate" suppressHydrationWarning>
      {typeof window === 'undefined' ? null : children}
    </div>
  )
}
SafeHydrate.propTypes = {
  children: PropTypes.any,
}

export default function CustomApp({ Component, pageProps }) {
  const store = useStore(pageProps.initialReduxState)

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <script key="adapter.js" src="/compatibility/adapter.js"></script>
        <script key="kurento-utils.js" src="/compatibility/kurento-utils.js"></script>
        <script key="kurento-client.js" src="/compatibility/kurento-client.js"></script>
        <script key="web-streams-polyfill.min.js" src="/compatibility/web-streams-polyfill.min.js"></script>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        ></meta>
      </Head>
      <Global styles={globalStyles} />
      <TailwindGlobalStyles />
      <SafeHydrate>
        <AuthProvider>
          <Provider store={store}>
            <Component {...pageProps} />
          </Provider>
        </AuthProvider>
      </SafeHydrate>
    </ThemeProvider>
  )
}
CustomApp.propTypes = {
  Component: PropTypes.elementType,
  pageProps: PropTypes.object,
}
